import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Tooltip, notification } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import {
  GenerateReportButton,
  CalculationSetupBox,
  OpenSelectLocomotiveModal,
  OpenNodeSelectionModal,
  NodeLabel,
  PlusOutlinedIcon,
  RollbackOutlinedIcon,
} from "./CalculationSetup.styled";
import LocomotiveSelectForm from "../../../components/LocomotiveSelectForm/LocomotiveSelectForm";
import AlgorithmSelectForm from "../AlgorithmSelectForm/AlgorithmSelectForm";
import { IReportParams } from "./types";
import DatePicker from "./DatePicker/DatePicker";
import {
  LOCOMOTIVE_SELECT_FORM,
  NODE_SELECT_FORM,
  DATE_FORMAT,
  LIMIT_LOCOMOTIVES,
} from "./constants";
import APP_CONFIG from "../../../appConfig";
import axios from "axios";
import { ColumnsManager } from "../../../components/ColumnsManager/ColumnsManager";
import { ErrorHandling } from "../../../helpers/errorMessage";

const { LOCOMOTIVES } = APP_CONFIG;

const initialReportParams = {
  locomotiveList: [],
  allLocomotiveSelect: false,
  algorithmsList: [],
  allAlgorithmsSelect: false,
  dateFrom: null,
  dateTo: null,
  nodeList: [],
};

const CalculationSetup = (props) => {
  const { calculationReport, loading, token, columns, toggleColumn } = props;
  const [api, contextHolder] = notification.useNotification();
  const [isOpenLocomotiveSelectFormOpen, setOpenLocomotiveSelectFormOpen] =
    useState(false);
  const [isOpenAlgorithmSelectForm, setOpenAlgorithmSelectForm] =
    useState(false);
  const [reportParams, setReportParams] =
    useState<IReportParams>(initialReportParams);
  const {
    locomotiveList,
    allLocomotiveSelect,
    allAlgorithmsSelect,
    algorithmsList,
    dateFrom,
    dateTo,
  } = reportParams;
  const urlSearch = window.location.search;
  const urlParams = new URLSearchParams(urlSearch);

  useEffect(() => {
    if (urlParams.get("id")) {
      setUrlParameters();
    }
  }, []);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Произошла ошибка",
      description: description,
      placement,
    });
  };

  const setUrlParameters = async () => {
    try {
      const {
        data: { results },
      } = await axios.get(`${LOCOMOTIVES}?limit=${LIMIT_LOCOMOTIVES}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const getCurrentLocomotive = urlParams
        .get("id")
        .split(",")
        .map((id) => {
          const getLocomotive = results.find((i) => {
            return i.id === Number(id);
          });

          if (getLocomotive) {
            return { ...getLocomotive };
          }
          return;
        });
      const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
      setReportParams((params: any) => {
        return {
          ...params,
          dateFrom: dayjs(
            new Date(
              moment(urlParams.get("start_date"), FORMAT_DATE).format(
                DATE_FORMAT
              )
            )
          ),
          dateTo: dayjs(
            new Date(
              moment(urlParams.get("finish_date"), FORMAT_DATE).format(
                DATE_FORMAT
              )
            )
          ),
          locomotiveList: getCurrentLocomotive,
        };
      });
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
    }
  };

  const openModal = (event) => {
    if (event === LOCOMOTIVE_SELECT_FORM) {
      return setOpenLocomotiveSelectFormOpen(true);
    }
    return setOpenAlgorithmSelectForm(true);
  };

  const closeModal = () => {
    setOpenLocomotiveSelectFormOpen(false);
    setOpenAlgorithmSelectForm(false);
  };

  const getSelectedLocomotivesCount = () => {
    if (allLocomotiveSelect === true) {
      return "Все";
    }
    if (locomotiveList?.length > 0) {
      return locomotiveList.length;
    }
    return "Не выбрано";
  };

  const getSelectedAlgorithmsCount = () => {
    if (allAlgorithmsSelect === true) {
      return "Все";
    }
    if (algorithmsList?.length > 0) {
      return algorithmsList.length;
    }
    return "Не выбрано";
  };

  const calculateReport = () => {
    const idsLocomotives = locomotiveList.map((item) => item.id);
    const idsAlgorithms = algorithmsList.map((item) => item.id);
    const body: any = {
      start_date: moment.utc(new Date(dateFrom)).set("second", 0).format(),
      finish_date: moment.utc(new Date(dateTo)).set("second", 0).format(),
    };
    if (idsLocomotives.length > 0) {
      body.locomotives = idsLocomotives;
    }
    if (idsAlgorithms.length > 0) {
      body.algorithm_ids = idsAlgorithms;
    }
    calculationReport(body);
  };

  const isDisabledStartCalculationButton = useMemo(() => {
    const noSelectedDates = !dateFrom || !dateTo;
    if (noSelectedDates) {
      return true;
    }
    return false;
  }, [reportParams]);

  const successSaveReportParams = (placement) => {
    api.success({
      message: "Параметры запуска расчета",
      description: "Данные успешно сохранены",
      placement,
    });
  };

  const saveReportParams = () => {
    const reportParams: string = JSON.stringify({
      locomotiveList: locomotiveList,
      dateFrom: dateFrom,
      dateTo: dateTo,
      algorithmsList: algorithmsList,
    });

    successSaveReportParams("topRight");
    localStorage.setItem("calcResourceParams", reportParams);
  };

  const getSavedParams = () => {
    const savedParamsString = localStorage.getItem("calcResourceParams");
    if (savedParamsString !== null) {
      const savedParams = JSON.parse(savedParamsString);

      const preparedParams = {
        ...savedParams,
        dateFrom: savedParams.dateFrom ? dayjs(savedParams.dateFrom) : null,
        dateTo: savedParams.dateTo ? dayjs(savedParams.dateTo) : null,
      };
      setReportParams(preparedParams);
    }
  };

  return (
    <CalculationSetupBox>
      {contextHolder}
      <Row>
        {isOpenLocomotiveSelectFormOpen && (
          <LocomotiveSelectForm
            isModalOpen={isOpenLocomotiveSelectFormOpen}
            closeModal={closeModal}
            reportParams={reportParams}
            setReportParams={setReportParams}
            token={token}
          />
        )}
        {isOpenAlgorithmSelectForm && (
          <AlgorithmSelectForm
            isModalOpen={isOpenAlgorithmSelectForm}
            closeModal={closeModal}
            setReportParams={setReportParams}
            reportParams={reportParams}
            token={token}
          />
        )}
        <Col span={2} style={{ fontSize: "larger" }}>
          <div>Локомотивы:</div>
          <NodeLabel>Алгоритмы:</NodeLabel>
        </Col>
        <Col span={2}>
          <OpenSelectLocomotiveModal
            onClick={() => openModal(LOCOMOTIVE_SELECT_FORM)}
          >
            {getSelectedLocomotivesCount()}
          </OpenSelectLocomotiveModal>
          <OpenNodeSelectionModal onClick={() => openModal(NODE_SELECT_FORM)}>
            {getSelectedAlgorithmsCount()}
          </OpenNodeSelectionModal>
        </Col>
        <DatePicker
          reportParams={reportParams}
          setReportParams={setReportParams}
        />
        <Col span={1}>
          <Row>
            <Col>
              <Tooltip title="Сохранить параметры">
                <PlusOutlinedIcon onClick={saveReportParams} />
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <RollbackOutlinedIcon
                title="Восстановить параметры"
                onClick={getSavedParams}
                disabled={localStorage.getItem("calcResourceParams") === null}
              />
            </Col>
          </Row>
        </Col>
        <Col span={11}>
          <GenerateReportButton
            shape="round"
            type="primary"
            onClick={calculateReport}
            disabled={loading || isDisabledStartCalculationButton}
          >
            ЗАПУСТИТЬ РАСЧЕТ
          </GenerateReportButton>
        </Col>
        {/* <Col span={1} push={2}>
          <DownloadIcon
            onClick={() => {
              alert(232);
            }}
          />
        </Col> */}
        <Col span={1} push={2}>
          <ColumnsManager columns={columns} toggleColumn={toggleColumn} />
        </Col>
      </Row>
    </CalculationSetupBox>
  );
};

export default CalculationSetup;
