import React from "react";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import ReportTable from "./reportTable/ReportTable";

const PAGE_NAME = "Остаточный ресурс";

const ResidualResource = (props) => {
  const { token } = props;
  return (
    <>
      <PageHeader pageName={PAGE_NAME} />
      <ReportTable token={token} />
    </>
  );
};

export default ResidualResource;
