import React from "react";
import { TableOutlined, CalculatorOutlined } from "@ant-design/icons";

export const ITEMS = [
  {
    key: "1",
    icon: <TableOutlined />,
    name: "Остаточный ресурс",
    link: "/residualResource",
  },
  {
    key: "2",
    icon: <CalculatorOutlined />,
    name: "Расчет остаточного ресурса",
    link: "/calculationResource",
  },
];
