import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Tooltip, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import queryString from "query-string";
import moment from "moment";
import dayjs from "dayjs";
import {
  DownloadIcon,
  GenerateReportButton,
  PlusOutlinedIcon,
  ReportSetupBox,
  RollbackOutlinedIcon,
  SelectAllButton,
  UploadExcelSpin,
} from "./ReportSetup.styled";
import DatePicker from "../../../components/DatePicker/DatePicker";
import LocomotiveSelectForm from "../../../components/LocomotiveSelectForm/LocomotiveSelectForm";
import { IReportParams } from "./types";
import APP_CONFIG from "../../../appConfig";
import axios from "axios";
import { ColumnsManager } from "../../../components/ColumnsManager/ColumnsManager";
import { ErrorHandling } from "../../../helpers/errorMessage";

const initialReportParams = {
  locomotiveList: [],
  allLocomotiveSelect: false,
  dateFrom: null,
  dateTo: null,
};

const LIMIT_LOCOMOTIVES = 500;

const { RESIDUAL_REPORT_EXPORT_EXCEL, LOCOMOTIVES } = APP_CONFIG;

const DATE_FORMAT = "YYYY-MM-DDTHH:mm";

const ReportSetup = (props) => {
  const [api, contextHolder] = notification.useNotification();
  const { getData, loading, token, urlParams, columns, toggleColumn } = props;
  const [isOpenLocomotiveSelectForm, setOpenLocomotiveSelectForm] =
    useState(false);
  const [reportParams, setReportParams] =
    useState<IReportParams>(initialReportParams);
  const { locomotiveList, dateFrom, dateTo, allLocomotiveSelect } =
    reportParams;
  const [uploadExcel, setUploadExcel] = useState(false);

  useEffect(() => {
    if (urlParams.get("start_date")) {
      setUrlParametersAndGenerateReport();
    }
  }, []);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Произошла ошибка",
      description: description,
      placement,
    });
  };

  const getLocomotives = async () => {
    try {
      const {
        data: { results },
      } = await axios.get(`${LOCOMOTIVES}?limit=${LIMIT_LOCOMOTIVES}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const preparedLocomotives = urlParams
        .get("ids")
        .split(",")
        .map((id) => {
          const getLocomotive = results.find((i) => {
            return i.id === Number(id);
          });

          if (getLocomotive) {
            return { ...getLocomotive };
          }
          return;
        });
      return preparedLocomotives;
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
    }
  };

  const setUrlParametersAndGenerateReport = async () => {
    const getUrlIdsLocomotives = urlParams.get("ids");
    let urlLocomotives = [];
    if (getUrlIdsLocomotives) {
      urlLocomotives = await getLocomotives();
    }
    const preparedParams = {
      dateFrom: dayjs(urlParams.get("start_date")),
      dateTo: dayjs(urlParams.get("finish_date")),
      allLocomotiveSelect: false,
      locomotiveList: urlLocomotives,
    };
    setReportParams((params) => {
      return { ...params, ...preparedParams };
    });
    const { dateFrom, dateTo } = preparedParams;
    const queryParams = {
      dt_from: dayjs(dateFrom).toISOString(),
      dt_to: dayjs(dateTo).toISOString(),
    };
    if (urlLocomotives.length > 0) {
      queryParams["locomotive_ids"] = urlLocomotives
        ?.map((item) => item.id)
        .toString();
    }
    getData(queryParams);
  };

  const openModal = () => {
    setOpenLocomotiveSelectForm(true);
  };

  const closeModal = () => {
    setOpenLocomotiveSelectForm(false);
  };

  const getSelectedLocomotivesCount = () => {
    if (allLocomotiveSelect === true) {
      return "Все";
    }
    if (locomotiveList.length > 0) {
      return locomotiveList.length;
    }
    return "Не выбрано";
  };

  const generateReport = () => {
    const idsLocomotives = locomotiveList.map((item) => item.id).toString();
    const query: any = {
      dt_from: moment.utc(new Date(dateFrom)).set("second", 0).format(),
      dt_to: moment.utc(new Date(dateTo)).set("second", 0).format(),
    };
    if (idsLocomotives) {
      query.locomotive_ids = idsLocomotives;
    }

    getData(query);
  };

  const isDisabledStartReportButton = useMemo(() => {
    const noSelectedDates = !dateFrom || !dateTo;
    if (noSelectedDates) {
      return true;
    }
    return false;
  }, [reportParams]);

  const successSaveReportParams = (placement) => {
    api.success({
      message: "Параметры формирования отчета",
      description: "Данные успешно сохранены",
      placement,
    });
  };

  const saveReportParams = () => {
    const reportParams: string = JSON.stringify({
      locomotiveList: locomotiveList,
      dateFrom: dateFrom,
      dateTo: dateTo,
      allLocomotiveSelect,
    });

    successSaveReportParams("topRight");
    localStorage.setItem("reportParams", reportParams);
  };

  const getSavedParams = () => {
    const savedParamsString = localStorage.getItem("reportParams");
    if (savedParamsString !== null) {
      const savedParams = JSON.parse(savedParamsString);

      const preparedParams = {
        ...savedParams,
        dateFrom: savedParams.dateFrom ? dayjs(savedParams.dateFrom) : null,
        dateTo: savedParams.dateTo ? dayjs(savedParams.dateTo) : null,
      };
      setReportParams(preparedParams);
    }
  };

  const exportDataExcel = async () => {
    if (!dateFrom || !dateTo) {
      return api.error({
        message: "Ошибка",
        description: "Даты обязательны к заполнению",
      });
    }
    setUploadExcel(true);
    const preparedLocomotives = locomotiveList.map((i) => i.id);
    const queryParams = {
      dt_from: moment.utc(new Date(dateFrom)).set("second", 0).format(),
      dt_to: moment.utc(new Date(dateTo)).set("second", 0).format(),
      locomotive_ids:
        preparedLocomotives.length > 0 ? preparedLocomotives.toString() : [],
    };
    const preparedQueryParams = queryString.stringify(queryParams);
    const url = `${RESIDUAL_REPORT_EXPORT_EXCEL}?${preparedQueryParams}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
        responseType: "arraybuffer",
      });
      setUploadExcel(false);
      const urls = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urls;
      link.setAttribute(
        "download",
        `aggregate_report_${moment(new Date(dateFrom)).format(
          DATE_FORMAT
        )}___${moment(new Date(dateTo)).format(DATE_FORMAT)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setUploadExcel(false);
    }
  };

  return (
    <ReportSetupBox>
      <Row>
        {isOpenLocomotiveSelectForm && (
          <LocomotiveSelectForm
            isModalOpen={isOpenLocomotiveSelectForm}
            closeModal={closeModal}
            reportParams={reportParams}
            setReportParams={setReportParams}
            token={token}
          />
        )}
        {contextHolder}
        <Col span={2} style={{ fontSize: "larger" }}>
          Локомотивы:
        </Col>
        <Col span={2}>
          <SelectAllButton onClick={openModal}>
            {getSelectedLocomotivesCount()}
          </SelectAllButton>
        </Col>
        <DatePicker
          reportParams={reportParams}
          setReportParams={setReportParams}
        />
        <Col span={1}>
          <Row>
            <Col>
              <Tooltip title="Сохранить параметры">
                <PlusOutlinedIcon onClick={saveReportParams} />
              </Tooltip>
            </Col>
          </Row>
          <Row>
            <Col>
              <RollbackOutlinedIcon
                title="Восстановить параметры"
                onClick={getSavedParams}
                disabled={localStorage.getItem("reportParams") === null}
              />
            </Col>
          </Row>
        </Col>
        <Col span={9}>
          <GenerateReportButton
            shape="round"
            type="primary"
            onClick={generateReport}
            disabled={isDisabledStartReportButton || loading}
          >
            СФОРМИРОВАТЬ ОТЧЕТ
          </GenerateReportButton>
        </Col>
        <Col span={1} push={3}>
          <UploadExcelSpin
            indicator={<LoadingOutlined />}
            spinning={uploadExcel}
            style={{ position: "relative", left: "-15px", top: "45px" }}
          >
            <DownloadIcon onClick={exportDataExcel} />
          </UploadExcelSpin>
        </Col>
        <Col span={1} push={3}>
          <ColumnsManager columns={columns} toggleColumn={toggleColumn} />
        </Col>
      </Row>
    </ReportSetupBox>
  );
};

export default ReportSetup;
