import { TableComponent } from "./SimpleTable.styled";

const SimpleTable = (props) => {
  const { scrollY } = props;

  const getScrollParam = () => {
    if (scrollY) {
      return scrollY;
    }
    return "calc(100vh - 340px)";
  };

  return (
    <TableComponent
      {...props}
      scroll={{ y: getScrollParam() }}
      pagination={{
        showSizeChanger: true,
        locale: { items_per_page: "" },
        pageSizeOptions: ["10", "20", "30"],
        style: { marginRight: "18px" },
      }}
    />
  );
};

export default SimpleTable;
