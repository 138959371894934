import React, { useState } from "react";
import { withCookies } from "react-cookie";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ru_RU";
import "dayjs/locale/ru";
import "./App.css";
import LoginPage from "./pages-components/auth/LoginPage";
import ResidualResource from "./pages-components/residualResource/ResidualResource";
import CalculationResource from "./pages-components/calculationResource/CalculationResource";
import { Routes, Route, useNavigate } from "react-router-dom";
import MainHeader from "./components/MainHeader/MainHeader";
import { Context } from "./context";
import ReportDetailTable from "./pages-components/residualResource/reportDetailTable/ReportDetailTable";
import ConfigProvider from "antd/lib/config-provider";
dayjs.locale("ru");

const App = (props) => {
  const navigate = useNavigate();
  const [hideMenuNavigation, setHideMenuNavigation] = useState(true);
  const isRefreshToken = props.cookies.get("refreshToken");

  const onHideMenuNavigation = () => {
    setHideMenuNavigation(true);
  };

  return (
    <Context.Provider value={{ hideMenuNavigation, setHideMenuNavigation }}>
      <ConfigProvider locale={locale}>
        <MainHeader isRefreshToken={isRefreshToken} />
        <div onClick={onHideMenuNavigation}>
          <Routes>
            <Route
              path=""
              element={
                isRefreshToken ? (
                  <ResidualResource
                    navigate={navigate}
                    token={isRefreshToken}
                  />
                ) : (
                  <LoginPage navigate={navigate} />
                )
              }
            />
            <Route
              path="residualResource"
              element={
                isRefreshToken ? (
                  <ResidualResource
                    navigate={navigate}
                    token={isRefreshToken}
                  />
                ) : (
                  <LoginPage navigate={navigate} />
                )
              }
            />
            <Route
              path="calculationResource"
              element={
                isRefreshToken ? (
                  <CalculationResource
                    navigate={navigate}
                    token={isRefreshToken}
                  />
                ) : (
                  <LoginPage navigate={navigate} />
                )
              }
            />
            <Route
              path="reportDetailTable"
              element={
                isRefreshToken ? (
                  <ReportDetailTable
                    navigate={navigate}
                    token={isRefreshToken}
                  />
                ) : (
                  <LoginPage navigate={navigate} />
                )
              }
            />
          </Routes>
        </div>
      </ConfigProvider>
    </Context.Provider>
  );
};

const AppPage = withCookies(App);

export default AppPage;
