import React, { useEffect, useState } from "react";
import { Modal, Table, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { columns } from "./constnasts";
import axios from "axios";
import APP_CONFIG from "../../../appConfig";
import { ErrorHandling } from "../../../helpers/errorMessage";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const { ALGORITHMS } = APP_CONFIG;

const AlgorithmSelectForm = (props) => {
  const { isModalOpen, closeModal, reportParams, setReportParams, token } =
    props;
  const { algorithmsList } = reportParams;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [selectedRowsList, setSelectedRows] = useState<any>([]);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getData();
    setSelectedRows(algorithmsList);
  }, [algorithmsList]);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Произошла ошибка",
      description: description,
      placement,
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      // makeRequest.get(LOCOMOTIVES);
      // return;
      const {
        data: { results },
      } = await axios.get(ALGORITHMS, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const preparedData = results.map((i) => {
        return { key: i.id, ...i };
      });
      setLoading(false);
      setData(preparedData);
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setLoading(false);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      setSelectedRows(selectedRows);
    },
    selectedRowKeys: selectedRowsList?.map((item) => item.id),
  };

  const onConfirm = () => {
    if (data.length === selectedRowsList.length) {
      const isSelectedRows = selectedRowsList.length ? true : false;
      setReportParams((params) => {
        return {
          ...params,
          allAlgorithmsSelect: isSelectedRows,
          algorithmsList: selectedRowsList,
        };
      });
    }
    setReportParams((params) => {
      return { ...params, algorithmsList: selectedRowsList };
    });
    closeModal();
  };

  return (
    <>
      {contextHolder}
      <Modal
        width={1000}
        title="Выбор алгоритмов"
        open={isModalOpen}
        onCancel={closeModal}
        okText="Выбрать"
        cancelText="Отмена"
        onOk={onConfirm}
      >
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns}
          dataSource={data}
          loading={{ indicator: <LoadingOutlined />, spinning: loading }}
          scroll={{ y: 400 }}
          pagination={{
            locale: { items_per_page: "" },
          }}
        />
      </Modal>
    </>
  );
};

export default AlgorithmSelectForm;
