import React from "react";

interface ContextProps {
  hideMenuNavigation: boolean;
  setHideMenuNavigation: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Context = React.createContext<ContextProps>({
  hideMenuNavigation: false,
  setHideMenuNavigation: () => {},
});
