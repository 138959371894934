import _ from "lodash";
import moment from "moment";

export const getFiltersParentValues = (item, data) => {
  const column = item.dataIndex;
  const prepatedFiltersValues = data.map((i) => {
    return { text: i[column], value: i[column] };
  });
  const isNumber = Number(prepatedFiltersValues[0].text);
  if (isNumber) {
    prepatedFiltersValues.sort((a, b) => Number(a.text) - Number(b.text));
  }
  return _.uniqBy(prepatedFiltersValues, "text");
};

export const getSorterColumn = (item, dateTypeColumns, stringTypeColumns) => {
  const value = item.dataIndex;
  if (dateTypeColumns.includes(value)) {
    return (a, b) => moment(a[value]).unix() - moment(b[value]).unix();
  }

  if (stringTypeColumns.includes(value)) {
    return (a, b) => a[value].localeCompare(b[value]);
  }
  return (a, b) => a[value] - b[value];
};
