import styled from "styled-components";
import { Form, Button } from "antd";

export const FormStyled = styled(Form)`
  max-width: 300px;
  margin: auto;
  position: relative;
  top: 250px;
  outline: 2px solid #000da8;
  outline-offset: 80px;
`;

export const Title = styled.div`
  text-align: center;
  position: relative;
  bottom: 50px;
  font-size: x-large;
  font-weight: 500;
`;

export const LoginButton = styled(Button)`
  background-color: #000da8;
  width: 150px;
  borderradius: inherit;
`;

export const InputStyle = {
  borderRadius: "inherit",
};

export const LabelStyle = { span: 24, style: { fontWeight: 500 } };
