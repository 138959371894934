import _ from "lodash";

const getFilterStringValue = (data, value) => {
  const prepatedFiltersValues = data.map((i) => {
    return { text: _.get(i, value), value: _.get(i, value) };
  });
  return _.uniqBy(prepatedFiltersValues, "text");
};

const getFilterNumberValue = (data, value) => {
  const prepatedFiltersValues = data.map((i) => {
    return { text: i[value], value: i[value] };
  });
  prepatedFiltersValues.sort((a, b) => Number(a[value]) - Number(b[value]));
  return _.uniqBy(prepatedFiltersValues, "text");
};

const getOnFilter = (record, value, column) =>
  _.get(record, column)?.indexOf(value) === 0;

export const getColumns: any = (data) => {
  return [
    {
      title: "Серия",
      dataIndex: "series",
      key: "name",
      render: (series) => series.name,
      sorter: (a, b) => {
        return a.series.name.localeCompare(b.series.name);
      },
      filters: getFilterStringValue(data, "series.name"),
      onFilter: (value: string, record) =>
        getOnFilter(record, value, "series.name"),
    },
    {
      title: "Номер",
      dataIndex: "number",
      key: "number",
      sorter: (a, b) => {
        return a.number - b.number;
      },
      filters: getFilterNumberValue(data, "number"),
      onFilter: (value: string, record) => getOnFilter(record, value, "number"),
    },
    {
      title: "Секция",
      dataIndex: "section",
      key: "section",
      sorter: (a, b) => {
        return a.section.localeCompare(b.section);
      },
      filters: getFilterStringValue(data, "section"),
      onFilter: (value: string, record) =>
        getOnFilter(record, value, "section"),
    },
    {
      title: "Идентификатор",
      dataIndex: "identifier",
      key: "identifier",
      sorter: (a, b) => {
        return a.identifier - b.identifier;
      },
      filters: getFilterNumberValue(data, "identifier"),
      onFilter: (value: string, record) =>
        getOnFilter(record, value, "identifier"),
    },
  ];
};
