import React from "react";
import { Form, Input, message } from "antd";
import { withCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  FormStyled,
  InputStyle,
  LabelStyle,
  LoginButton,
  Title,
} from "./LoginPage.styled";
import APP_CONFIG from "../../appConfig";
import { ErrorHandling } from "../../helpers/errorMessage";
const { AUTH } = APP_CONFIG;

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const login = async (values: { username: string; password: string }) => {
    const { username, password } = values;
    try {
      const {
        data: { token },
      } = await axios.post(AUTH, {
        username: username,
        password: password,
      });
      props.cookies.set("refreshToken", token, {
        path: "/",
        secure: false,
        sameSite: "strict",
      });
      navigate("/residualResource");
    } catch (error) {
      let description;
      if (error?.response?.data?.non_field_errors) {
        description = error?.response?.data?.non_field_errors;
        showErrorField();
        showErrorMessage(description);
        return;
      }
      showErrorField();
      const errorCode = error?.response?.status;
      description = ErrorHandling(errorCode);
      showErrorMessage(description);
      console.error(error);
    }
  };

  const showErrorMessage = (description) => {
    messageApi.open({
      type: "error",
      content: description,
      className: "custom-class",
      style: {
        marginTop: "20vh",
      },
    });
  };

  const showErrorField = () => {
    form.setFields([
      {
        name: "username",
        errors: [""],
      },
      {
        name: "password",
        errors: [""],
      },
    ]);
  };

  return (
    <FormStyled
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={login}
      autoComplete="off"
    >
      {contextHolder}
      <Title>Вход в систему</Title>
      <Form.Item
        label="Логин"
        name="username"
        rules={[{ required: true, message: "Введите логин" }]}
        labelCol={LabelStyle}
      >
        <Input style={InputStyle} />
      </Form.Item>

      <Form.Item
        label="Пароль"
        name="password"
        rules={[{ required: true, message: "Введите пароль" }]}
        labelCol={LabelStyle}
      >
        <Input.Password style={InputStyle} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
        <LoginButton type="primary" htmlType="submit">
          Войти
        </LoginButton>
      </Form.Item>
    </FormStyled>
  );
};

const LoginPageComponent = withCookies(LoginPage);

export default LoginPageComponent;
