export const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
export const COLUMN_WIDTH = 150;

export const DATE_TYPE_COLUMN = ["start_date", "finish_date"];

export const STRING_TYPE_COLUMN = [
  "polygon",
  "home_depot_name",
  "loco_section",
  "loco_series",
];

export const REPORT_TABLE_VISIBLE_COLUMNS = "reportTableVisibleColumns";
