import styled from "styled-components";
import { Table } from "antd";

export const TableComponent = styled(Table)`
  .ant-table-thead .ant-table-cell {
    border: 1px solid #ebe9e9 !important;
  }
  position: relative;
  bottom: 1px;
  height: 100% !important;
`;
