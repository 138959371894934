import React from "react";
import { Row, Col } from "antd";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
  DateEndInput,
  DateStartInput,
  dateEndLabel,
  dateEndPicker,
} from "./DatePicker.styled";

const FORMAT_DATE = "DD.MM.YYYY HH.mm";

const DATE_FROM = "dateFrom";
const DATE_TO = "dateTo";

const DatePicker = (props) => {
  const {
    reportParams: { dateFrom, dateTo },
    setReportParams,
  } = props;

  const disabledEndDate = (endValue) => {
    if (!dateFrom) {
      return false;
    }
    return endValue.valueOf() < dateFrom.valueOf();
  };

  const disabledStartDate = (startValue) => {
    if (!dateTo) {
      return false;
    }
    return startValue.valueOf() > dateTo.valueOf();
  };

  const onChangeEndDate = (value) => {
    setReportParams((params) => {
      return { ...params, dateTo: value };
    });
  };

  const onChangeStartDate = (value) => {
    setReportParams((params) => {
      return { ...params, dateFrom: value };
    });
  };

  const deleteDateValue = (dateString, datePosition) => {
    if (!dateString) {
      setReportParams((params) => {
        return { ...params, [datePosition]: null };
      });
    }
  };

  return (
    <>
      <Col span={1}>
        <Row>
          <Col>
            <div>Начало:</div>
          </Col>
        </Row>
        <Row>
          <Col style={dateEndLabel}>
            <div>Окончание:</div>
          </Col>
        </Row>
      </Col>
      <Col span={4} style={{ left: "20px" }}>
        <Row>
          <Col>
            <DateStartInput
              locale={locale}
              onChange={(dateString) => deleteDateValue(dateString, DATE_FROM)}
              format={FORMAT_DATE}
              disabledDate={disabledStartDate}
              onSelect={onChangeStartDate}
              showTime={true}
              value={dateFrom}
            />
          </Col>
        </Row>
        <Row>
          <Col style={dateEndPicker}>
            <DateEndInput
              locale={locale}
              onChange={(dateString) => deleteDateValue(dateString, DATE_TO)}
              format={FORMAT_DATE}
              disabledDate={disabledEndDate}
              onSelect={onChangeEndDate}
              showTime={true}
              value={dateTo}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DatePicker;
