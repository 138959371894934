import React, { useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import moment from "moment";
import queryString from "query-string";
import { LoadingOutlined } from "@ant-design/icons";
import {
  COLUMN_WIDTH,
  PAGE_NAME,
  DATE_FORMAT,
  DATE_TYPE_COLUMN,
  STRING_TYPE_COLUMN,
  DETAIL_REPORT_VISIBLE_COLUMNS,
} from "./constnats";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import ReportInfoPanel from "./reportInfoPanel/ReportInfoPanel";
import APP_CONFIG from "../../../appConfig";
import axios from "axios";
import { getColumns } from "./helpers";
import ModalData from "../../../components/ModalData/ModalData";
import { getSorterColumn } from "../../../helpers";
import SimpleTable from "../../../components/Table";
import { ErrorHandling } from "../../../helpers/errorMessage";

const { DETAIL_REPORT, DETAIL_REPORT_EXPORT_EXCEL } = APP_CONFIG;

const ReportDetailTable = (props) => {
  const { token } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [uploadExcel, setUploadExcel] = useState(false);
  const [headetData, setHeaderData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", message: "" });
  const [columns, setColumns] = useState([]);
  const urlSearch = window.location.search;
  const urlParams = new URLSearchParams(urlSearch);

  useEffect(() => {
    getData();
  }, []);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Произошла ошибка",
      description: description,
      placement,
    });
  };

  const getData = async () => {
    setLoading(true);
    const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
    const dateFrom = urlParams.get("start_date");
    const dateTo = urlParams.get("finish_date");
    const id = urlParams.get("id");
    const query = {
      dt_from: moment.utc(dateFrom, FORMAT_DATE).subtract(3, "hour").format(),
      dt_to: moment.utc(dateTo, FORMAT_DATE).subtract(3, "hour").format(),
      locomotive_id: id,
    };
    const preparedQuery = queryString.stringify(query);
    try {
      const url = `${DETAIL_REPORT}?${preparedQuery}`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const { header, data: tableData } = data;
      const preparedTableData = prepareTableData(tableData);
      const preparedHeaderData = prepareHeaderData(header);
      setLoading(false);
      setHeaderData(preparedHeaderData);
      setData(preparedTableData);
    } catch (error) {
      setLoading(false);
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
    }
  };

  const prepareHeaderData = (data) => {
    const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
    data.dt_from = moment(new Date(data.dt_from)).format(FORMAT_DATE);
    data.dt_to = moment(new Date(data.dt_to)).format(FORMAT_DATE);
    return data;
  };

  const prepareTableData = (data) => {
    const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
    return data.map((i) => {
      return {
        ...i,
        start_dt: moment(i.start_dt).format(FORMAT_DATE),
        finish_dt: moment(i.finish_dt).format(FORMAT_DATE),
      };
    });
  };

  const prepareColumns = (columnsList) => {
    return columnsList.map((item) => {
      const updatedItem = {
        ...item,
        width: COLUMN_WIDTH,
        sorter:
          !item.children &&
          getSorterColumn(item, DATE_TYPE_COLUMN, STRING_TYPE_COLUMN),
        children:
          item.children &&
          item.children.map((child) => ({
            ...child,
            width: COLUMN_WIDTH,
            sorter: getSorterColumn(item, DATE_TYPE_COLUMN, STRING_TYPE_COLUMN),
          })),
      };
      return updatedItem;
    });
  };

  const showModal = (text, title) => {
    setModalData({ message: text, title: title });
    setOpenModal(true);
  };

  useMemo(() => {
    const id = urlParams.get("id");
    let columns = getColumns(showModal, id);
    if (
      JSON.parse(localStorage.getItem(DETAIL_REPORT_VISIBLE_COLUMNS)) &&
      JSON.parse(localStorage.getItem(DETAIL_REPORT_VISIBLE_COLUMNS))[6]
        .title === "Пробег км"
    ) {
      localStorage.removeItem(DETAIL_REPORT_VISIBLE_COLUMNS);
    }
    const getLocalStorageVisibleColumns = JSON.parse(
      localStorage.getItem(DETAIL_REPORT_VISIBLE_COLUMNS)
    );
    if (getLocalStorageVisibleColumns) {
      columns = columns.map((columnItem) => {
        const isHiden = getLocalStorageVisibleColumns.find(
          (i) => i.dataIndex === columnItem.dataIndex && i.hidden === true
        );
        if (isHiden) {
          return { ...columnItem, hidden: true };
        } else {
          return { ...columnItem };
        }
      });
    }
    const preparedColumns = prepareColumns(columns);
    setColumns(preparedColumns);
  }, [data]);

  const exportDataExcel = async () => {
    setUploadExcel(true);
    const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
    const dateFrom = urlParams.get("start_date");
    const dateTo = urlParams.get("finish_date");
    const id = urlParams.get("id");
    const query = {
      dt_from: moment.utc(dateFrom, FORMAT_DATE).subtract(3, "hour").format(),
      dt_to: moment.utc(dateTo, FORMAT_DATE).subtract(3, "hour").format(),
      locomotive_id: id,
    };
    const preparedQueryParams = queryString.stringify(query);
    const url = `${DETAIL_REPORT_EXPORT_EXCEL}?${preparedQueryParams}`;
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
        responseType: "arraybuffer",
      });
      setUploadExcel(false);
      const urls = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = urls;
      link.setAttribute(
        "download",
        `detail_report_${moment(dateFrom, FORMAT_DATE).format(
          DATE_FORMAT
        )}___${moment(dateTo, FORMAT_DATE).format(DATE_FORMAT)}_ID=${id}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setUploadExcel(false);
    }
  };

  const toggleColumn = (column) => {
    const {
      target: { id, checked },
    } = column;
    const copyColumns = [...columns];
    copyColumns[id].hidden = !checked;
    localStorage.setItem(
      DETAIL_REPORT_VISIBLE_COLUMNS,
      JSON.stringify(copyColumns)
    );
    setColumns(copyColumns);
  };

  const getVisibleColumns = () => columns.filter((column) => !column.hidden);

  return (
    <>
      {contextHolder}
      {openModal && (
        <ModalData
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalData={modalData}
        />
      )}
      <PageHeader pageName={PAGE_NAME} />
      <ReportInfoPanel
        headerData={headetData}
        exportDataExcel={exportDataExcel}
        loading={loading}
        uploadExcel={uploadExcel}
        columns={columns}
        toggleColumn={toggleColumn}
      />
      <SimpleTable
        bordered
        columns={getVisibleColumns()}
        dataSource={data}
        loading={{ indicator: <LoadingOutlined />, spinning: loading }}
        size="middle"
        scrollY={"calc(100vh - 400px)"}
      />
    </>
  );
};

export default ReportDetailTable;
