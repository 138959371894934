import styled from "styled-components";
import { DatePicker } from "antd";

export const DateStartInput = styled(DatePicker)`
  width: 200px;
`;

export const DateEndInput = styled(DatePicker)`
  width: 200px;
`;

export const dateEndPicker: React.CSSProperties = {
  position: "relative",
  bottom: "21px",
};

export const dateEndLabel: React.CSSProperties = {
  position: "relative",
  bottom: "21px",
};
