export const COLUMN_WIDTH = 200;

export const PAGE_NAME = "Остаточный ресурс (детализация)";

export const DATE_FORMAT = "YYYY-MM-DDTHH:mm";

export const DATE_TYPE_COLUMN = ["start_dt", "finish_dt"];

export const STRING_TYPE_COLUMN = [
  "start_station_name",
  "finish_station_name",
  "locomotive_work_status",
];

export const DETAIL_REPORT_VISIBLE_COLUMNS = "detailReportVisibleColumns";
