import { Button } from "antd";
import styled from "styled-components";
import { RightCircleOutlined } from "@ant-design/icons";

const RightCircleOutlinedIcon = styled(RightCircleOutlined)`
  font-size: 25px;
  color: #000da8;
`;

const goToCalculateResourceReport = (start_dt, finish_dt, id) => {
  const url = `/calculationResource/?id=${id}&start_date=${start_dt}&finish_date=${finish_dt}`;
  window.open(url, "_blank");
};

export const getScrollY = () => {
  if (window.innerWidth > 1910) {
    return 700;
  } else if (window.innerWidth > 1520) {
    return 320;
  } else {
    return 400;
  }
};

export const getColumns = (showModal, id) => {
  return [
    {
      title: "Переход к запуску расчета",
      dataIndex: "",
      render: (render) => {
        const { start_dt, finish_dt } = render;
        return (
          <RightCircleOutlinedIcon
            onClick={() => goToCalculateResourceReport(start_dt, finish_dt, id)}
          />
        );
      },
      align: "center",
    },
    {
      title: "ID поездки",
      dataIndex: "locomotive_work_id",
      sorter: (a, b) => {
        return a.locomotive_work_id - b.locomotive_work_id;
      },
    },
    {
      title: "Начальная станция",
      dataIndex: "start_station_name",
      sorter: (a, b) =>
        a.start_station_name.localeCompare(b.start_station_name),
    },
    {
      title: "Конечная станция",
      dataIndex: "finish_station_name",
      sorter: (a, b) =>
        a.finish_station_name.localeCompare(b.finish_station_name),
    },
    {
      title: "Начало",
      dataIndex: "start_dt",
    },
    {
      title: "Окончание",
      dataIndex: "finish_dt",
    },
    {
      title: "Пробег, км",
      dataIndex: "mileage",
    },
    {
      title: "Объем грузоперевозок, МТ*км",
      dataIndex: "cargo_volume",
    },
    {
      title: "Работа, ГДж",
      dataIndex: "useful_work",
      sorter: (a, b) => {
        return a.useful_work - b.useful_work;
      },
    },
    {
      title: "Статус",
      dataIndex: "locomotive_work_status",
    },

    {
      title: "Сообщение",
      dataIndex: "extra_data",
      render: (text) =>
        text?.error && (
          <Button
            type="primary"
            style={{ backgroundColor: "#000da8" }}
            onClick={() => {
              const title = "Сообщение";
              const message = text.error;
              showModal(message, title);
            }}
          >
            посмотреть
          </Button>
        ),
    },

    {
      title: "Изоляция ТЭД",
      dataIndex: "ted_insulation",
      children: [
        {
          title: "Изменение ресурса, км",
          dataIndex: "ted_insulation_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "ted_insulation_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, км",
          dataIndex: "ted_insulation_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "ted_insulation_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, км",
          dataIndex: "ted_insulation_operation_life_abs",
        },
      ],
    },

    {
      title: "Щёточно-коллекторный узел",
      dataIndex: "brush_collector",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "brush_collector_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "brush_collector_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "brush_collector_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "brush_collector_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "brush_collector_operation_life_abs",
        },
      ],
    },

    {
      title: "Моторно-якорный подшипник ТЭД (1)",
      dataIndex: "ted_anchor_bearing_1",
      children: [
        {
          title: "Изменение ресурса, км",
          dataIndex: "ted_anchor_bearing_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "ted_anchor_bearing_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, км",
          dataIndex: "ted_anchor_bearing_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "ted_anchor_bearing_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, км",
          dataIndex: "ted_anchor_bearing_1_operation_life_abs",
        },
      ],
    },
    {
      title: "Моторно-якорный подшипник ТЭД (2)",
      dataIndex: "ted_anchor_bearing_2",
      children: [
        {
          title: "Изменение ресурса, км",
          dataIndex: "ted_anchor_bearing_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "ted_anchor_bearing_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, км",
          dataIndex: "ted_anchor_bearing_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "ted_anchor_bearing_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, км",
          dataIndex: "ted_anchor_bearing_2_operation_life_abs",
        },
      ],
    },
    {
      title: "Вставка токоприёмника",
      dataIndex: "current_collector_insert",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "current_collector_insert_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "current_collector_insert_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "current_collector_insert_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "current_collector_insert_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "current_collector_insert_operation_life_abs",
        },
      ],
    },
    {
      title: "Буксовый подшипник",
      dataIndex: "box_bearing",
      children: [
        {
          title: "Изменение ресурса, км",
          dataIndex: "box_bearing_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "box_bearing_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, км",
          dataIndex: "box_bearing_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "box_bearing_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, км",
          dataIndex: "box_bearing_operation_life_abs",
        },
      ],
    },
    {
      title: "Пружина буксовой ступени подвешивания",
      dataIndex: "box_spring",
      children: [
        {
          title: "Изменение ресурса, у. е. поврежд.",
          dataIndex: "box_spring_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "box_spring_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, у. е. поврежд.",
          dataIndex: "box_spring_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "box_spring_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, у. е. поврежд.",
          dataIndex: "box_spring_operation_life_abs",
        },
      ],
    },
    {
      title: "Кожух зубчатой передачи",
      dataIndex: "gear_housing",
      children: [
        {
          title: "Изменение ресурса, сек. воздейств.",
          dataIndex: "gear_housing_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "gear_housing_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, сек. воздейств.",
          dataIndex: "gear_housing_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "gear_housing_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, сек. воздейств.",
          dataIndex: "gear_housing_operation_life_abs",
        },
      ],
    },
    {
      title: "Шестерня тягового редуктора",
      dataIndex: "traction_reducer_pinion",
      children: [
        {
          title: "Изменение ресурса, мм износа",
          dataIndex: "traction_reducer_pinion_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "traction_reducer_pinion_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм износа",
          dataIndex: "traction_reducer_pinion_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "traction_reducer_pinion_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм износа",
          dataIndex: "traction_reducer_pinion_operation_life_abs",
        },
      ],
    },
    {
      title: "Зубчатое колесо тягового редуктора",
      dataIndex: "traction_reducer_cogwheel",
      children: [
        {
          title: "Изменение ресурса, мм износа",
          dataIndex: "traction_reducer_cogwheel_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "traction_reducer_cogwheel_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм износа",
          dataIndex: "traction_reducer_cogwheel_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "traction_reducer_cogwheel_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм износа",
          dataIndex: "traction_reducer_cogwheel_operation_life_abs",
        },
      ],
    },
    {
      title: "Гребень колеса",
      dataIndex: "wheel_flange",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "wheel_flange_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "wheel_flange_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "wheel_flange_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "wheel_flange_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "wheel_flange_operation_life_abs",
        },
      ],
    },
    {
      title: "Прокат колеса",
      dataIndex: "wheel_tread",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "wheel_tread_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "wheel_tread_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "wheel_tread_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "wheel_tread_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "wheel_tread_operation_life_abs",
        },
      ],
    },
    {
      title: "Моторно-осевой подшипник",
      dataIndex: "ted_axle_bearing",
      children: [
        {
          title: "Изменение ресурса, км",
          dataIndex: "ted_axle_bearing_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "ted_axle_bearing_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, км",
          dataIndex: "ted_axle_bearing_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "ted_axle_bearing_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, км",
          dataIndex: "ted_axle_bearing_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка нижняя втулка балансира и Валик-втулка нижней тяги (№ сечения 1)",
      dataIndex: "pin_bushing_2_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_2_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_2_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_2_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_2_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_2_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка нижняя втулка балансира и Валик-втулка нижней тяги (№ сечения 2)",
      dataIndex: "pin_bushing_2_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_2_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_2_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_2_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_2_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_2_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка нижняя втулка балансира и Валик-втулка нижней тяги (№ сечения 3)",
      dataIndex: "pin_bushing_2_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_2_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_2_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_2_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_2_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_2_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-средняя втулка балансира и Валик-нижняя втулка подвески и Валик-втулка вилки штока цилиндра (№ сечения 1)",
      dataIndex: "pin_bushing_1_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_1_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_1_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_1_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_1_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_1_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-средняя втулка балансира и Валик-нижняя втулка подвески и Валик-втулка вилки штока цилиндра (№ сечения 2)",
      dataIndex: "pin_bushing_1_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_1_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_1_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_1_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_1_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_1_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-средняя втулка балансира и Валик-нижняя втулка подвески и Валик-втулка вилки штока цилиндра (№ сечения 3)",
      dataIndex: "pin_bushing_1_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_1_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_1_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_1_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_1_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_1_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-средняя втулка балансира и Валик-нижняя втулка подвески и Валик-втулка вилки штока цилиндра (№ сечения 4)",
      dataIndex: "pin_bushing_1_4",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_1_4_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_1_4_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_1_4_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_1_4_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_1_4_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-средняя втулка балансира и Валик-нижняя втулка подвески и Валик-втулка вилки штока цилиндра (№ сечения 5)",
      dataIndex: "pin_bushing_1_5",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_1_5_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_1_5_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_1_5_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_1_5_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_1_5_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик- верхняя втулка балансира и Валик-втулка планки (№ сечения 1)",
      dataIndex: "pin_bushing_3_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_3_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_3_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_3_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_3_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_3_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик- верхняя втулка балансира и Валик-втулка планки (№ сечения 2)",
      dataIndex: "pin_bushing_3_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_3_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_3_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_3_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_3_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_3_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик- верхняя втулка балансира и Валик-втулка планки (№ сечения 3)",
      dataIndex: "pin_bushing_3_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_3_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_3_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_3_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_3_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_3_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка подвески и Валик-втулка башмака колодки и Валик-средняя втулка подвески (№ сечения 1)",
      dataIndex: "pin_bushing_7_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_7_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_7_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_7_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_7_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_7_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка подвески и Валик-втулка башмака колодки и Валик-средняя втулка подвески (№ сечения 2)",
      dataIndex: "pin_bushing_7_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_7_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_7_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_7_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_7_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_7_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка подвески и Валик-втулка башмака колодки и Валик-средняя втулка подвески (№ сечения 3)",
      dataIndex: "pin_bushing_7_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_7_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_7_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_7_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_7_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_7_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка подвески и Валик-втулка башмака колодки и Валик-средняя втулка подвески (№ сечения 4)",
      dataIndex: "pin_bushing_7_4",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_7_4_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_7_4_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_7_4_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_7_4_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_7_4_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка подвески и Валик-втулка башмака колодки и Валик-средняя втулка подвески (№ сечения 5)",
      dataIndex: "pin_bushing_7_5",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_7_5_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_7_5_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_7_5_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_7_5_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_7_5_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-втулка планки (№ сечения 1)",
      dataIndex: "pin_bushing_4_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_4_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_4_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_4_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_4_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_4_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-втулка планки (№ сечения 2)",
      dataIndex: "pin_bushing_4_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_4_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_4_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_4_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_4_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_4_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-втулка планки (№ сечения 3)",
      dataIndex: "pin_bushing_4_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_4_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_4_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_4_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_4_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_4_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка продольной тяги и Валик-втулка поперечины (№ сечения 1)",
      dataIndex: "pin_bushing_22_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_22_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_22_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_22_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_22_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_22_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка продольной тяги и Валик-втулка поперечины (№ сечения 2)",
      dataIndex: "pin_bushing_22_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_22_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_22_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_22_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_22_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_22_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка продольной тяги и Валик-втулка поперечины (№ сечения 3)",
      dataIndex: "pin_bushing_22_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_22_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_22_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_22_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_22_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_22_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка продольной тяги и Валик-втулка поперечины (Камень) (№ сечения 1)",
      dataIndex: "pin_bushing_18_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_18_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_18_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_18_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_18_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_18_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка продольной тяги и Валик-втулка поперечины (Камень) (№ сечения 2)",
      dataIndex: "pin_bushing_18_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_18_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_18_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_18_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_18_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_18_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "алик-втулка продольной тяги и Валик-втулка поперечины (Камень) (№ сечения 3)",
      dataIndex: "pin_bushing_18_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_18_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_18_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_18_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_18_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_18_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка башмака колодки и Валик - средняя втулка подвески (№ сечения 1)",
      dataIndex: "pin_bushing_24_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_24_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_24_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_24_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_24_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_24_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка башмака колодки и Валик - средняя втулка подвески (№ сечения 2)",
      dataIndex: "pin_bushing_24_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_24_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_24_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_24_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_24_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_24_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик-втулка башмака колодки и Валик - средняя втулка подвески (№ сечения 3)",
      dataIndex: "pin_bushing_24_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_24_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_24_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_24_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_24_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_24_3_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-кронштейн подвески (№ сечения 1)",
      dataIndex: "pin_bushing_25_1",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_25_1_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_25_1_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_25_1_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_25_1_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_25_1_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-кронштейн подвески (№ сечения 2)",
      dataIndex: "pin_bushing_25_2",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_25_2_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_25_2_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_25_2_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_25_2_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_25_2_operation_life_abs",
        },
      ],
    },
    {
      title:
        "Валик - верхняя втулка подвески и Валик-кронштейн подвески (№ сечения 3)",
      dataIndex: "pin_bushing_25_3",
      children: [
        {
          title: "Изменение ресурса, мм",
          dataIndex: "pin_bushing_25_3_resource_change_abs",
        },
        {
          title: "Изменение ресурса, %",
          dataIndex: "pin_bushing_25_3_resource_change_percent",
        },
        {
          title: "Остаточный ресурс, мм",
          dataIndex: "pin_bushing_25_3_remaining_life_abs",
        },
        {
          title: "Остаточный ресурс, %",
          dataIndex: "pin_bushing_25_3_remaining_life_percent",
        },
        {
          title: "Расчетный ресурс, мм",
          dataIndex: "pin_bushing_25_3_operation_life_abs",
        },
      ],
    },
  ];
};
