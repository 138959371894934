export const SettingIcon: React.CSSProperties = {
  fontSize: "xx-large",
  color: "#000da8",
  position: "relative",
  top: "25px",
};

export const DropDownStyle: React.CSSProperties = {
  maxHeight: "300px",
  overflow: "auto",
  border: "2px solid rgb(241 241 241)",
};
