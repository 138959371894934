import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { notification } from "antd";
import {
  REQUEST_TIMER,
  PAGE_NAME,
  CALCULATION_TABLE_VISIBLE_COLUMNS,
} from "./constants";
import CalculationSetup from "./calculationSetup/CalculationSetup";
import APP_CONFIG from "../../appConfig";
import axios from "axios";
import ModalData from "../../components/ModalData/ModalData";
import { getColumns } from "./helpers";
import SimpleTable from "../../components/Table";
import { ErrorHandling } from "../../helpers/errorMessage";

const { CALCULATION_RESOURCE_LIST, MANUAL_RUN } = APP_CONFIG;

const CalculationResource = (props) => {
  const { token } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", message: "" });
  const [api, contextHolder] = notification.useNotification();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
    const timer = setInterval(() => {
      getData();
    }, REQUEST_TIMER);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const successMessage = (placement) => {
    api.success({
      message: "Ручной запуск",
      description: "Задача на расчет износа успешно создана",
      placement,
    });
  };

  const errorMessage = (placement, description) => {
    api.error({
      message: "Ручной запуск",
      description: description,
      placement,
    });
  };

  const getData = async () => {
    try {
      const url = `${CALCULATION_RESOURCE_LIST}?ordering=-id`;
      const {
        data: { results },
      } = await axios.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const preparedData = prepareData(results);
      setData(preparedData);
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
    }
  };

  const calculationReport = async (body) => {
    setLoading(true);
    try {
      await axios.post(MANUAL_RUN, body, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      successMessage("topRight");
      setLoading(false);
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setLoading(false);
    }
  };

  const prepareData = (data) => {
    const FORMAT_DATE = "DD.MM.YYYY, HH:mm:ss";
    return data.map((i) => {
      return {
        ...i,
        created_on: i.created_on && moment(i.created_on).format(FORMAT_DATE),
        started_on: i.started_on && moment(i.started_on).format(FORMAT_DATE),
        finished_on: i.finished_on && moment(i.finished_on).format(FORMAT_DATE),
      };
    });
  };

  const showModal = (text, title) => {
    setModalData({ message: text, title: title });
    setOpenModal(true);
  };

  useMemo(() => {
    let columns = getColumns(showModal);
    const getLocalStorageVisibleColumns = JSON.parse(
      localStorage.getItem(CALCULATION_TABLE_VISIBLE_COLUMNS)
    );
    if (getLocalStorageVisibleColumns) {
      columns = columns.map((columnItem) => {
        const isHiden = getLocalStorageVisibleColumns.find(
          (i) => i.dataIndex === columnItem.dataIndex && i.hidden === true
        );
        if (isHiden) {
          return { ...columnItem, hidden: true };
        } else {
          return { ...columnItem };
        }
      });
    }
    setColumns(columns);
  }, [data]);

  const toggleColumn = (column) => {
    const {
      target: { id, checked },
    } = column;
    const copyColumns = [...columns];
    copyColumns[id].hidden = !checked;
    localStorage.setItem(
      CALCULATION_TABLE_VISIBLE_COLUMNS,
      JSON.stringify(copyColumns)
    );
    setColumns(copyColumns);
  };

  const getVisibleColumns = () => columns.filter((column) => !column.hidden);

  return (
    <>
      {contextHolder}
      {openModal && (
        <ModalData
          openModal={openModal}
          setOpenModal={setOpenModal}
          modalData={modalData}
        />
      )}
      <PageHeader pageName={PAGE_NAME} />
      <CalculationSetup
        calculationReport={calculationReport}
        loading={loading}
        token={token}
        columns={columns}
        toggleColumn={toggleColumn}
      />
      <SimpleTable
        bordered
        columns={getVisibleColumns()}
        dataSource={data}
        size="small"
      />
    </>
  );
};

export default CalculationResource;
