import styled from "styled-components";
import { Layout } from "antd";

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  text-align: left;
  color: #000da8;
  height: 64;
  padding-inline: 50;
  line-height: 46px;
  background-color: white;
  font-size: x-large;
  outline: 1px solid #939393;
  outline-offset: -1px;
  height: 50px;
  font-weight: 600;
`;
