import React, { useEffect, useMemo, useState } from "react";
import { Modal, Table, notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { DataType, LIMIT_LOCOMOTIVES } from "./constants";
import APP_CONFIG from "../../appConfig";
// import makeRequest from "../../../utils/network";
import axios from "axios";
import { getColumns } from "./helpers";
import { ErrorHandling } from "../../helpers/errorMessage";

const { LOCOMOTIVES } = APP_CONFIG;

const LocomotiveSelectForm = (props) => {
  const { isModalOpen, closeModal, reportParams, setReportParams, token } =
    props;
  const { locomotiveList } = reportParams;
  const [data, setData] = useState<any>([]);
  const [selectedRowsList, setSelectedRows] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    getData();
    setSelectedRows(locomotiveList);
  }, [locomotiveList]);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Произошла ошибка",
      description: description,
      placement,
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const {
        data: { results },
      } = await axios.get(`${LOCOMOTIVES}?limit=${LIMIT_LOCOMOTIVES}`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const preparedData = results.map((i) => {
        let checked = false;
        const isCheckedLocomotive = locomotiveList.find(
          (item) => item.id === i.id
        );
        if (isCheckedLocomotive) {
          checked = true;
        }
        return { key: i.id, checked: checked, ...i };
      });

      preparedData.sort((a, b) => {
        if (a.checked !== b.checked) {
          return b.checked - a.checked;
        } else if (a.series.name !== b.series.name) {
          return a.series.name.localeCompare(b.series.name);
        } else if (a.number !== b.number) {
          return a.number - b.number;
        } else {
          return a.section.localeCompare(b.section);
        }
      });
      setLoading(false);
      setData(preparedData);
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setLoading(false);
    }
  };

  const rowSelection: any = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const addCheckedFlag = selectedRows.map((i) => {
        return { ...i, checked: true };
      });
      setSelectedRows(addCheckedFlag);
    },
    selectedRowKeys: selectedRowsList?.map((item) => item.id),
  };

  const onConfirm = () => {
    if (data.length === selectedRowsList.length) {
      const isSelectedRows = selectedRowsList.length ? true : false;
      setReportParams((params) => {
        return {
          ...params,
          allLocomotiveSelect: isSelectedRows,
          locomotiveList: selectedRowsList,
        };
      });
    }
    setReportParams((params) => {
      return { ...params, locomotiveList: selectedRowsList };
    });
    closeModal();
  };

  const onCloseModal = () => {
    closeModal();
  };

  const columns = useMemo(() => {
    return getColumns(data);
  }, [data]);

  return (
    <>
      {contextHolder}
      <Modal
        width={1000}
        title="Выбор секций локомотивов"
        open={isModalOpen}
        onCancel={onCloseModal}
        okText="Выбрать"
        cancelText="Отмена"
        onOk={onConfirm}
      >
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          loading={{ indicator: <LoadingOutlined />, spinning: loading }}
          columns={columns}
          dataSource={data}
          scroll={{ y: 400 }}
          pagination={{
            locale: { items_per_page: "" },
          }}
        />
        <div>Кол-во выбранных: {selectedRowsList?.length} </div>
      </Modal>
    </>
  );
};

export default LocomotiveSelectForm;
