import React from "react";
import { Modal } from "antd";
import { bodyStyle, cancelButtonProps } from "./ModalData.styled";

export interface IModalData {
  setOpenModal: (boolean) => void;
  openModal: boolean;
  modalData: { title: string; message: string };
}

const ModalData: React.FC<IModalData> = (props) => {
  const { setOpenModal, openModal, modalData } = props;
  const { title, message } = modalData;

  return (
    <>
      <Modal
        title={title}
        centered
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={700}
        bodyStyle={bodyStyle}
        cancelButtonProps={{ style: cancelButtonProps }}
      >
        <p>{message}</p>
      </Modal>
    </>
  );
};

export default ModalData;
