import React from "react";
import { Spin } from "antd";
import {
  DownloadIcon,
  ReportInfoPanelBox,
  SettingIcon,
  TableManagerBox,
  Value,
} from "./ReportInfoPanel.styled";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { ColumnsManager } from "../../../../components/ColumnsManager/ColumnsManager";

const ReportInfoPanel = (props) => {
  const {
    uploadExcel,
    exportDataExcel,
    loading,
    columns,
    toggleColumn,
    headerData: {
      dt_from,
      dt_to,
      polygon,
      mileage_total,
      cargo_volume_total,
      useful_work_total,
      home_depot_name,
      loco_series_name,
      loco_number,
      loco_section,
    },
  } = props;

  return (
    <div>
      <ReportInfoPanelBox>
        <div>
          Локомотив:{" "}
          <Value>
            {loco_series_name}-{loco_number}
            {loco_section}
          </Value>
        </div>
        <div>
          Начало: <Value>{dt_from}</Value>
        </div>
        <div>
          Пробег, км: <Value>{mileage_total}</Value>
        </div>
        <div>
          Полигон: <Value>{polygon}</Value>
        </div>
        <div>
          Окончание: <Value>{dt_to}</Value>
        </div>
        <div>
          Объем грузоперевозок, МТ*км: <Value>{cargo_volume_total}</Value>
        </div>
        <div>
          Депо приписки: <Value>{home_depot_name}</Value>
        </div>
        <div></div>
        <div>
          Работа, ГДж: <Value>{useful_work_total}</Value>
        </div>
      </ReportInfoPanelBox>
      <TableManagerBox>
        <Spin
          indicator={<LoadingOutlined />}
          spinning={uploadExcel}
          style={DownloadIcon}
        >
          <DownloadOutlined
            style={{ fontSize: "32px", marginRight: "43px", color: "#000da8" }}
            onClick={exportDataExcel}
            disabled={loading}
          />
        </Spin>
        <ColumnsManager
          columns={columns}
          toggleColumn={toggleColumn}
          settingIconStyle={SettingIcon}
        />
      </TableManagerBox>
    </div>
  );
};

export default ReportInfoPanel;
