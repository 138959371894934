import { Button } from "antd";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import { RightCircleOutlined } from "@ant-design/icons";
import { DATE_FORMAT } from "./constants";

const RightCircleOutlinedIcon = styled(RightCircleOutlined)`
  font-size: 25px;
  ${({ disabled }) =>
    !disabled
      ? `
          color: #000da8;
        `
      : `
          color: lightgray;
        `}
`;

const goToReport = (parameters) => {
  const { start_date, finish_date } = parameters;
  const locomotivesIds = _.get(parameters, "locomotives");
  const startDate = moment.unix(start_date).format(DATE_FORMAT);
  const finishDate = moment.unix(finish_date).format(DATE_FORMAT);
  let url = `/residualResource/?start_date=${startDate}&finish_date=${finishDate}`;
  if (locomotivesIds) {
    url = `/residualResource/?ids=${locomotivesIds?.toString()}&start_date=${startDate}&finish_date=${finishDate}`;
  }
  window.open(url, "_blank");
};

const prepareValueParameter = (kwargs) => {
  const DATE_FORMAT = "DD.MM.YYYY, HH:mm:ss";
  const paramslist = Object.entries(kwargs).map(([key, value]) => ({
    [key]: value,
  }));
  return (
    <div>
      {paramslist.map((item) => {
        const key = Object.keys(item)[0];
        let value = item[key] as any;

        if (Array.isArray(value) || typeof value === "boolean") {
          value = item[key].toString();
        } else if (isNaN(Number(moment.unix(value).format(DATE_FORMAT)))) {
          value = moment.unix(value).format(DATE_FORMAT);
        } else {
        }

        return (
          <>
            <br />
            {key}: {value}
          </>
        );
      })}
    </div>
  );
};

export const getColumns = (showModal) => {
  return [
    {
      title: "ID записи",
      dataIndex: "id",
      width: 100,
      align: "center",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "ID задачи",
      dataIndex: "task_id",
      width: 100,
      sorter: (a, b) => a.task_id.localeCompare(b.task_id),
    },
    {
      title: "Наименование задачи",
      dataIndex: "task_name",
      width: 100,
      sorter: (a, b) => a.task_name.localeCompare(b.task_name),
    },
    {
      title: "Пользователь",
      dataIndex: "user",
      width: 100,
      sorter: (a, b) => a?.user?.localeCompare(b.user),
    },
    {
      title: "Дата создания",
      dataIndex: "created_on",
      width: 100,
      sorter: (a, b) =>
        moment(a.created_on).unix() - moment(b.created_on).unix(),
    },
    {
      title: "Дата начала",
      dataIndex: "started_on",
      width: 100,
      sorter: (a, b) =>
        moment(a.started_on).unix() - moment(b.started_on).unix(),
    },
    {
      title: "Дата завершения",
      dataIndex: "finished_on",
      width: 100,
      sorter: (a, b) =>
        moment(a.finished_on).unix() - moment(b.finished_on).unix(),
    },
    {
      title: "Статус",
      dataIndex: "state",
      width: 100,
      align: "center",
      sorter: (a, b) => a.state - b.state,
    },
    {
      title: "Параметры запуска",
      dataIndex: "args",
      width: 100,
      render: (text) =>
        !_.isEmpty(text?.kwargs) && (
          <Button
            type="primary"
            style={{ backgroundColor: "#000da8" }}
            onClick={() => {
              const { kwargs } = text;
              const title = "Параметры запуска";
              const params = prepareValueParameter(kwargs);
              showModal(params, title);
            }}
          >
            посмотреть
          </Button>
        ),
    },
    {
      title: "Сообщение об ошибке",
      dataIndex: "traceback",
      width: 100,
      render: (text) =>
        text && (
          <Button
            type="primary"
            style={{ backgroundColor: "#000da8" }}
            onClick={() => {
              const title = "Сообщение об ошибке";
              showModal(text, title);
            }}
          >
            посмотреть
          </Button>
        ),
    },
    {
      title: "Переход к отчету",
      dataIndex: "args",
      width: 100,
      align: "center",
      render: (text, render) => {
        const { task_name, state } = render;
        const parameters = text?.kwargs;
        const disabled =
          state === "Выполнена" && !_.isEmpty(parameters) ? false : true;
        const clickableIcon =
          !disabled && !_.isEmpty(parameters)
            ? () => goToReport(parameters)
            : null;
        return (
          task_name === "Ручной запуск обработки поездок" && (
            <RightCircleOutlinedIcon
              onClick={clickableIcon}
              disabled={disabled}
            />
          )
        );
      },
    },
  ];
};
