const API_URL = "https://backend.spr-test.k8s.itclover.ru/api";
console.log(process.env);
const getUrls = {
  auth: () => {
    return {
      AUTH: `${API_URL}/token/`,
    };
  },
  locomotive: () => {
    return {
      LOCOMOTIVES: `${API_URL}/locomotives/`,
    };
  },
  reports: () => {
    return {
      AGG_REPORT: `${API_URL}/wear/agg_report`,
      DETAIL_REPORT: `${API_URL}/wear/detail_report`,
    };
  },
  calculationResource: () => {
    return {
      CALCULATION_RESOURCE_LIST: `${API_URL}/task_registry/async_task/`,
      MANUAL_RUN: `${API_URL}/math_model/manual_run`,
    };
  },
  algorithm: () => {
    return {
      ALGORITHMS: `${API_URL}/math_model/algorithm/`,
    };
  },
  exportFiles: () => {
    return {
      RESIDUAL_REPORT_EXPORT_EXCEL: `${API_URL}/wear/agg_report/export`,
      DETAIL_REPORT_EXPORT_EXCEL: `${API_URL}/wear/detail_report/export`,
    };
  },
};

const APP_CONFIG = {
  ...getUrls.auth(),
  ...getUrls.locomotive(),
  ...getUrls.reports(),
  ...getUrls.calculationResource(),
  ...getUrls.algorithm(),
  ...getUrls.exportFiles(),
};

export default APP_CONFIG;
