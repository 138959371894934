import styled from "styled-components";
import { Layout, Button } from "antd";
import {
  DownloadOutlined,
  SettingOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

export const CalculationSetupBox = styled(Header)`
  text-align: left;
  color: black;
  padding-inline: 17px;
  line-height: 64px;
  background-color: white;
  height: 100px;
  outline: 1px solid #939393;
  outline-offset: -1px;
`;

export const DownloadIcon = styled(DownloadOutlined)`
  font-size: xx-large;
  color: #000da8;
  position: relative;
  top: 25px;
`;

export const SettingsIcon = styled(SettingOutlined)`
  font-size: xx-large;
  color: #000da8;
  position: relative;
  top: 25px;
`;

export const GenerateReportButton = styled(Button)`
  width: 300px;
  background-color: #000da8;
`;

export const OpenSelectLocomotiveModal = styled(Button)`
  width: 91%;
  border-color: #000da8;
`;

export const OpenNodeSelectionModal = styled(Button)`
  width: 91%;
  border-color: #000da8;
  bottom: 18px;
`;
export const NodeLabel = styled.div`
  bottom: 18px;
  position: relative;
`;

export const PlusOutlinedIcon = styled(PlusOutlined)`
  font-size: 25px;
  color: #000da8;
`;

export const RollbackOutlinedIcon = styled(RollbackOutlined)`
  font-size: 25px;
  position: relative;
  bottom: 12px;
  color: #000da8;
`;
