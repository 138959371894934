import React from "react";
import { Menu } from "antd";
import { ITEMS } from "./constants";
import { Link, useLocation } from "react-router-dom";
import {
  MenuStyle,
  NameItem,
  NavigationMenuBox,
} from "./NavigationMenu.styled";

const NavigationMenu = ({ showMenuNavogation }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <NavigationMenuBox>
      <div className="demo-logo-vertical" />
      <MenuStyle
        inlineCollapsed={showMenuNavogation}
        mode="inline"
        selectedKeys={[currentPath]}
      >
        {ITEMS.map((i) => {
          return (
            <Menu.Item key={i.link}>
              {i.icon}
              <Link to={i.link}></Link>
              <NameItem>{i.name}</NameItem>
            </Menu.Item>
          );
        })}
      </MenuStyle>
    </NavigationMenuBox>
  );
};

export default NavigationMenu;
