import React, { useContext } from "react";
import { withCookies } from "react-cookie";
import {
  HeaderStyled,
  MenuOutlinedButton,
  UserAccountButton,
} from "./MainHeader.styled";
import { Context } from "../../context";
import { MenuProps } from "antd/lib/menu";
import { Dropdown } from "antd";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const MainHeader: React.FC = (props: any) => {
  const { setHideMenuNavigation, hideMenuNavigation } = useContext(Context);
  const { isRefreshToken } = props;
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    props.cookies.remove("refreshToken", { path: "/" });
    navigate("");
  };

  const items: MenuProps["items"] = [
    { key: 1, label: "Выход", onClick: logOut },
  ];

  const onToggleNavigationMenu = () => {
    setHideMenuNavigation(!hideMenuNavigation);
  };

  const getUserAccountButton = () => {
    return (
      <Dropdown menu={{ items }}>
        <UserAccountButton type="link" icon={<UserOutlined />} hidden={true} />
      </Dropdown>
    );
  };

  return (
    <HeaderStyled>
      <MenuOutlinedButton
        type="link"
        icon={<MenuOutlined />}
        onClick={onToggleNavigationMenu}
      />
      Система прогнозирования ресурса
      {isRefreshToken && getUserAccountButton()}
    </HeaderStyled>
  );
};

const MainHeaderComponent = withCookies(MainHeader);

export default MainHeaderComponent;
