import React from "react";
import { Row, Col } from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/ru_RU";
import {
  DateEndInput,
  DateStartInput,
  dateEndLabel,
  dateEndPicker,
} from "./DatePicker.styled";

const FORMAT_DATE = "DD.MM.YYYY HH.mm";

const DATE_FROM = "dateFrom";
const DATE_TO = "dateTo";

const DatePicker = (props) => {
  const {
    reportParams: { dateFrom, dateTo },
    setReportParams,
  } = props;

  const onChangeEndDate = (value) => {
    setReportParams((params) => {
      return { ...params, dateTo: value };
    });
  };

  const onChangeStartDate = (value) => {
    setReportParams((params) => {
      return { ...params, dateFrom: value };
    });
  };

  const deleteDateValue = (dateString, datePosition) => {
    if (!dateString) {
      setReportParams((params) => {
        return { ...params, [datePosition]: null };
      });
    }
  };

  const disabledEndHours = () => {
    const endDateMinute = moment(new Date(dateTo)).minute();
    const startDateMinute = moment(new Date(dateFrom)).minute();
    const startDateHour = moment(new Date(dateFrom)).hour();
    const startDateDay = moment(new Date(dateFrom)).day();
    const endDateDay = moment(new Date(dateTo)).day();
    const endDateHour = moment(new Date(dateTo)).hour();
    return Array.from({ length: 24 }, (_, hour) => hour).filter((hour) => {
      if (dateFrom === null) {
        return false;
      }
      if (startDateDay === endDateDay) {
        return hour < startDateHour;
      }
      if (endDateMinute > startDateMinute && startDateHour > endDateHour) {
        return hour >= startDateHour;
      }
      return hour > startDateHour;
    });
  };

  const disabledEndMinutes = () => {
    const startDateMinute = moment(new Date(dateFrom)).minute();
    const startDateHour = moment(new Date(dateFrom)).hour();
    const endDateHour = moment(new Date(dateTo)).hour();
    const startDateDay = moment(new Date(dateFrom)).day();
    const endDateDay = moment(new Date(dateTo)).day();
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => {
        if (startDateDay === endDateDay && endDateHour === startDateHour) {
          return minute < startDateMinute;
        }
        if (endDateHour === startDateHour) {
          return minute > startDateMinute;
        }
        // return minute > startDateMinute;
        return false;
      }
    );
  };

  const disabledStartHours = () => {
    const endDateHour = moment(new Date(dateTo)).hour();
    const startDateHour = moment(new Date(dateFrom)).hour();
    const startDateDay = moment(new Date(dateFrom)).day();
    const endDateDay = moment(new Date(dateTo)).day();
    return Array.from({ length: 24 }, (_, hour) => hour).filter((hour) => {
      if (dateTo === null) {
        return false;
      }
      if (startDateDay === endDateDay && endDateHour === startDateHour) {
        return hour > endDateHour;
      }
      return hour < endDateHour;
    });
  };

  const disabledStartMinutes = () => {
    const endDateMinute = moment(new Date(dateTo)).minute();
    const startDateHour = moment(new Date(dateFrom)).hour();
    const endDateHour = moment(new Date(dateTo)).hour();
    const startDateDay = moment(new Date(dateFrom)).day();
    const endDateDay = moment(new Date(dateTo)).day();

    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => {
        if (endDateHour === startDateHour && startDateDay === endDateDay) {
          return minute < endDateMinute;
        }
        if (endDateHour === startDateHour) {
          return minute < endDateMinute;
        }
        return false;
      }
    );
  };

  return (
    <>
      <Col span={1}>
        <Row>
          <Col>
            <div>Начало:</div>
          </Col>
        </Row>
        <Row>
          <Col style={dateEndLabel}>
            <div>Окончание:</div>
          </Col>
        </Row>
      </Col>
      <Col span={4} style={{ left: "20px" }}>
        <Row>
          <Col>
            <DateStartInput
              showNow={false}
              locale={locale}
              onChange={(dateString) => deleteDateValue(dateString, DATE_FROM)}
              format={FORMAT_DATE}
              disabledHours={disabledStartHours}
              disabledMinutes={disabledStartMinutes}
              disabledDate={(current) => {
                const dayBeforeYesterday = moment
                  .utc(new Date(dateTo))
                  .subtract(2, "day");
                if (!dateTo) {
                  return false;
                }
                const currentDate = current.startOf("day");
                return (
                  (current && currentDate < dayBeforeYesterday) ||
                  currentDate > dateTo
                );
              }}
              onSelect={onChangeStartDate}
              showTime={true}
              value={dateFrom}
            />
          </Col>
        </Row>
        <Row>
          <Col style={dateEndPicker}>
            <DateEndInput
              showNow={false}
              locale={locale}
              onChange={(dateString) => deleteDateValue(dateString, DATE_TO)}
              format={FORMAT_DATE}
              onSelect={onChangeEndDate}
              disabledHours={disabledEndHours}
              disabledMinutes={disabledEndMinutes}
              disabledDate={(current) => {
                const dayAfterTomorrow = moment(new Date(dateFrom)).add(
                  2,
                  "day"
                );
                if (!dateFrom) {
                  return false;
                }
                const currentDate = current.endOf("day");

                const dateStart = dateFrom;
                return (
                  (current && currentDate < dateStart) ||
                  currentDate > dayAfterTomorrow
                );
              }}
              showTime={true}
              value={dateTo}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default DatePicker;
