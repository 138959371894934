import React, { useEffect, useMemo, useState } from "react";
import { notification } from "antd";
import _ from "lodash";
import { LoadingOutlined } from "@ant-design/icons";
import queryString from "query-string";
import moment from "moment";
import {
  COLUMN_WIDTH,
  DATE_TYPE_COLUMN,
  FORMAT_DATE,
  REPORT_TABLE_VISIBLE_COLUMNS,
  STRING_TYPE_COLUMN,
} from "./constants";
import { Link } from "react-router-dom";
import APP_CONFIG from "../../../appConfig";
import axios from "axios";
import ReportSetup from "../reportSetup/ReportSetup";
import { getColumnns } from "./helpers";
import { getFiltersParentValues, getSorterColumn } from "../../../helpers";
import SimpleTable from "../../../components/Table/index";
import { ErrorHandling } from "../../../helpers/errorMessage";

const { AGG_REPORT } = APP_CONFIG;

const ReportTable = (props) => {
  const urlSearch = window.location.search;
  const urlParams = new URLSearchParams(urlSearch);
  const { token } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [columnsList, setColumnsList] = useState([]);

  useEffect(() => {
    if (urlParams.get("id")) {
      const DATE_FORMAT = "YYYY-MM-DDTHH:mm";
      const queryParams = {
        locomotive_ids: urlParams.get("id"),
        dt_from: moment(new Date(urlParams.get("start_date"))).format(
          DATE_FORMAT
        ),
        dt_to: moment(new Date(urlParams.get("finish_date"))).format(
          DATE_FORMAT
        ),
      };
      getData(queryParams);
    }
  }, []);

  const errorMessage = (placement, description) => {
    api.error({
      message: "Ручной запуск",
      description: description,
      placement,
    });
  };

  const getData = async (queryParams) => {
    try {
      setLoading(true);
      const preparedQuery = queryString.stringify(queryParams);
      const url = `${AGG_REPORT}?${preparedQuery}`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      const preparedData = prepareData(data);
      setData(preparedData);
      setLoading(false);
    } catch (error) {
      const errorCode = error?.response?.status;
      const description = ErrorHandling(errorCode);
      errorMessage("topRight", description);
      setLoading(false);
    }
  };

  const prepareData = (data) => {
    return data.map((i) => {
      return {
        ...i,
        start_date: moment(i.start_date).format(FORMAT_DATE),
        finish_date: moment(i.finish_date).format(FORMAT_DATE),
      };
    });
  };

  const getFiltersChildValues = (childElement) => {
    return _.uniqBy(
      data.map((i) => {
        return {
          text: i[childElement.dataIndex],
          value: i[childElement.dataIndex],
        };
      }),
      "text"
    );
  };

  const getLinkRow = (text, rowData) => {
    return (
      <Link onClick={() => goToReportDetail(rowData)} to="">
        {text}
      </Link>
    );
  };

  const prepareColumns = (columnsList) => {
    return columnsList.map((item) => {
      const updatedItem = {
        ...item,
        width: COLUMN_WIDTH,
        sorter:
          !item.children &&
          getSorterColumn(item, DATE_TYPE_COLUMN, STRING_TYPE_COLUMN),
        render: (text: string, rowData) => getLinkRow(text, rowData),
        filters:
          !item.children && data.length
            ? getFiltersParentValues(item, data)
            : null,
        onFilter: (value: string, record) =>
          record[item.dataIndex].startsWith(value),
        children:
          item.children &&
          item.children.map((child) => ({
            ...child,
            width: COLUMN_WIDTH,
            sorter: getSorterColumn(item, DATE_TYPE_COLUMN, STRING_TYPE_COLUMN),
            render: (text: string, rowData) => getLinkRow(text, rowData),
            filters: getFiltersChildValues(child),
            onFilter: (value: string, record) =>
              record[item.dataIndex].startsWith(value),
          })),
      };
      return updatedItem;
    });
  };

  useMemo(() => {
    if (
      JSON.parse(localStorage.getItem(REPORT_TABLE_VISIBLE_COLUMNS)) &&
      JSON.parse(localStorage.getItem(REPORT_TABLE_VISIBLE_COLUMNS))[10]
        .title === "Работа, Дж"
    ) {
      localStorage.removeItem(REPORT_TABLE_VISIBLE_COLUMNS);
    }
    const columnsList =
      JSON.parse(localStorage.getItem(REPORT_TABLE_VISIBLE_COLUMNS)) ||
      getColumnns(data);
    const preparedColumns = prepareColumns(columnsList);
    setColumnsList(preparedColumns);
  }, [data]);

  const goToReportDetail = (rowData) => {
    const { locomotive_id, start_date, finish_date } = rowData;
    const url = `/reportDetailTable/?id=${locomotive_id}&start_date=${start_date}&finish_date=${finish_date}`;
    window.open(url, "_blank");
  };

  const toggleColumn = (column) => {
    const {
      target: { id, checked },
    } = column;
    const copyColumnsList = [...columnsList];
    copyColumnsList[id].hidden = !checked;
    localStorage.setItem(
      REPORT_TABLE_VISIBLE_COLUMNS,
      JSON.stringify(copyColumnsList)
    );
    setColumnsList(copyColumnsList);
  };

  const getVisibleColumns = () =>
    columnsList.filter((column) => !column.hidden);

  return (
    <>
      {" "}
      {contextHolder}
      <ReportSetup
        getData={getData}
        loading={loading}
        token={token}
        urlParams={urlParams}
        columns={columnsList}
        toggleColumn={toggleColumn}
      />
      <SimpleTable
        bordered
        columns={getVisibleColumns()}
        loading={{ indicator: <LoadingOutlined />, spinning: loading }}
        dataSource={data}
        size="large"
        scrollY={"calc(100vh - 420px)"}
      />
    </>
  );
};

export default ReportTable;
