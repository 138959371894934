import styled from "styled-components";
import { Menu } from "antd";

export const MenuStyle = styled(Menu)`
  outline: 1px solid #000da8;
  outline-offset: -1px;
  height: 100%;
  width: ${(props) => (props.inlineCollapsed ? 0 : "270px")};
`;

export const NavigationMenuBox = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  color: #000da8;
`;

export const NameItem = styled.span`
  margin-left: 10px;
`;
