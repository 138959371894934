import styled from "styled-components";

export const ReportInfoPanelBox = styled.div`
  display: grid;
  grid-template-columns: 300px 300px 300px;
  position: relative;
  left: 30px;
  margin: 10px;
`;

export const Value = styled.span`
  color: #0054ca;
  font-size: small;
  font-weight: 500;
`;

export const SettingIcon: React.CSSProperties = {
  fontSize: "32px",
  marginRight: "43px",
  color: "#000da8",
};

export const DownloadIcon: React.CSSProperties = {
  position: "relative",
  left: "-24px",
};

export const TableManagerBox = styled.div`
  float: right;
  display: flex;
  position: relative;
  bottom: 58px;
  right: 22px;
`;
