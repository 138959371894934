import React, { useContext } from "react";
import { HeaderStyled } from "./PageHeader.styled";
import NavigationMenu from "../NavigationMenu/NavigationMenu";
import { Context } from "../../context";

export const PageHeader = ({ pageName }) => {
  const { hideMenuNavigation } = useContext(Context);
  return (
    <>
      <HeaderStyled>{pageName}</HeaderStyled>
      <NavigationMenu showMenuNavogation={hideMenuNavigation} />
    </>
  );
};
