import styled from "styled-components";
import { Layout, Button, Spin } from "antd";
import {
  DownloadOutlined,
  SettingOutlined,
  PlusOutlined,
  RollbackOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

export const ReportSetupBox = styled(Header)`
  text-align: left;
  color: black;
  padding-inline: 17px;
  // padding-inline: 69px;
  line-height: 64px;
  background-color: white;
  height: 100px;
  outline: 1px solid #939393;
  outline-offset: -1px;
`;

export const DownloadIcon = styled(DownloadOutlined)`
  font-size: xx-large;
  color: #000da8;
  position: relative;
  top: 25px;
`;

export const SettingsIcon = styled(SettingOutlined)`
  font-size: xx-large;
  color: #000da8;
  position: relative;
  top: 25px;
`;

export const GenerateReportButton = styled(Button)`
  width: 300px;
  background-color: #000da8;
`;

export const SelectAllButton = styled(Button)`
  width: 91%;
  border-color: #000da8;
`;

export const PlusOutlinedIcon = styled(PlusOutlined)`
  font-size: 25px;
  color: #000da8;
`;

export const RollbackOutlinedIcon = styled(RollbackOutlined)`
  font-size: 25px;
  position: relative;
  bottom: 12px;
  color: #000da8;
`;

export const UploadExcelSpin = styled(Spin)`
  position: relative;
  left: -43px;
`;
