import React, { useMemo, useState } from "react";
import { Dropdown, MenuProps, Checkbox } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { DropDownStyle, SettingIcon } from "./ColumnManager.styled";

export const ColumnsManager = (props) => {
  const { toggleColumn, columns, settingIconStyle } = props;
  const [isOpen, setIsOpen] = useState(false);

  const getColumnsList = useMemo(() => {
    return columns.map((i, index) => {
      return {
        key: i.title,
        label: (
          <Checkbox onChange={toggleColumn} id={index} checked={!i.hidden}>
            {i.title}
          </Checkbox>
        ),
      };
    });
  }, [columns]);

  const items: MenuProps["items"] = getColumnsList;

  const handleSettingsClick = () => {
    setIsOpen(!isOpen);
  };

  const getSettingIconStyle = () => {
    return settingIconStyle || SettingIcon;
  };

  const handleOpenChange = (flag: boolean) => {
    setIsOpen(flag);
  };

  return (
    <Dropdown
      menu={{ items }}
      overlayStyle={DropDownStyle}
      open={isOpen}
      onOpenChange={handleOpenChange}
    >
      <SettingOutlined
        onClick={handleSettingsClick}
        style={getSettingIconStyle()}
      />
    </Dropdown>
  );
};
