export interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  number: string;
  created_at: string;
  updated_at: string;
}

export const LIMIT_LOCOMOTIVES = 500;
