import styled from "styled-components";
import { Layout, Button } from "antd";

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  text-align: center;
  line-height: 64px;
  background-color: rgb(219 219 219);
  color: #000da8;
  font-size: x-large;
  font-weight: 700;
`;

export const MenuOutlinedButton = styled(Button)`
  float: left;
  position: relative;
  top: 10px;
  right: 25px;
  background-color: rgb(219 219 219);
  color: #000da8;
  font-size: inherit;
`;

export const UserAccountButton = styled(Button)`
  float: right;
  position: relative;
  top: 10px;
  left: 25px;
  background-color: rgb(219 219 219);
  color: #000da8;
  font-size: inherit;
`;
